import { Box, Flex, Heading, Text, Divider } from "@chakra-ui/core"
import { graphql } from "gatsby"
import React, { FunctionComponent } from "react"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { Title } from "../components/title"

const BlogpostTemplate: FunctionComponent<{
  data: any
  pageContext: { lang: string }
}> = ({ data }) => {
  const blog = data.prismicBlogposts
  const { title, intro, content } = blog.data

  return (
    <Layout>
      <SEO title={title?.text} description={intro?.text} />
      <Box my={4}>
        <Title text={title?.text} />
        <Text fontSize="lg">{intro?.text}</Text>
        <Divider />
        <Box dangerouslySetInnerHTML={{ __html: content.html }} />
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query PostBySlug($uid: String!) {
    prismicBlogposts(id: { eq: $uid }) {
      first_publication_date
      last_publication_date
      data {
        published_at(formatString:"YYYY. MM. DD.")
        content {
          html
        }
        title {
          text
        }
        intro {
          text
        }
      }
    }
  }
`

export default BlogpostTemplate
